<template>
  <div class="Incidents">
    <div class="title">{{ textIncidentsHistory }}</div>
    <template v-if="isShowHistory">
      <div class="date" v-for="(historyItem, index) in history" :key="index">
        <div class="date-title" v-if="historyItem.incidents.length !== 0">
          {{ incidentHistoryDate(historyItem.date) }}
        </div>
        <incident-item v-for="incident in historyItem.incidents"
                       :key="incident.id"
                       :incident="incident"
                       :status-page-language="statusPageLanguage"
        />
      </div>
    </template>
    <div v-else class="no-incidents">
      {{ textNoIncidentsReported }}
    </div>

    <div class="title">{{ textMaintenanceHistory}}</div>
    <template v-if="isShowMaintenanceHistory">
      <div class="date" v-for="(historyItem, index) in maintenancesHistory" :key="index + 'm'">
        <MaintenanceHistoryItem :history-item="historyItem" :status-page-language="statusPageLanguage" />
      </div>
    </template>
    <div v-else class="no-incidents">
      {{ textNoMaintenanceHistory }}
    </div>
  </div>
</template>

<script>
import IncidentItem from '@/components/StatusPage/Incidents/IncidentsItem.vue'
import getUserLocale from 'get-user-locale'
import statusPagesApi from '@/api/statusPagesApi'
import MaintenanceHistoryItem from '@/components/StatusPage/Maintenance/MaintenanceHistoryItem.vue'

export default {
  components: {
    MaintenanceHistoryItem,
    IncidentItem
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      history: [],
      maintenancesHistory: [],
      statusPageLanguage: {}
    }
  },

  created () {
    this.getIncidents()

    this.getMaintenances()

    if (this.statusPage && this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }
  },

  methods: {
    async getIncidents () {
      try {
        const response = await statusPagesApi.getIncidentsHistoryBySlug(this.statusPage.slug)
        this.responseData = response.data
        for (let i = 0; i < response.data.length; i++) {
          let normal_date = response.data[i].created_at
          this.history.push({
            date: toTimestamp(normal_date.substr(0, normal_date.indexOf('T'))),
            incidents: []
          })
        }
        this.responseData.forEach(incident => {
          let date = toTimestamp(incident.created_at.substr(0, incident.created_at.indexOf('T')))
          let historyItem = this.history.find(item => item.date === date)
          incident.updates[0].created_at = toTimestamp(incident.updates[0].created_at)
          if (historyItem) {
            historyItem.incidents.push(incident)
          }
        })
      } catch (error) {
        console.error(error)
      }

      function toTimestamp (strDate) {
        var datum = Date.parse(strDate)
        return datum / 1000
      }
    },

    async getMaintenances () {
      try {
        const response = await statusPagesApi.getMaintenancesHistoryBySlug(this.statusPage.slug)
        this.maintenancesHistory = response.data
      } catch (error) {
        console.error(error)
      }
    },

    incidentHistoryDate (timestamp) {
      const locale = this.statusPageLanguage?.date_and_time_language?.type ?? getUserLocale()

      const day = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })

      return day.format(new Date(timestamp * 1000))
    }
  },
  computed: {
    isShowHistory () {
      return !!this.history.find(item => item.incidents.length !== 0)
    },

    isShowMaintenanceHistory () {
      return this.maintenancesHistory.length > 0
    },

    textIncidentsHistory () {
      if (this.statusPageLanguage.text_incidents_history) {
        return this.statusPageLanguage.text_incidents_history
      }

      return 'Incidents History'
    },

    textNoMaintenanceHistory () {
      if (this.statusPageLanguage.text_no_maintenance) {
        return this.statusPageLanguage.text_no_maintenance
      }
      return 'No maintenance.'
    },

    textNoIncidentsReported () {
      if (this.statusPageLanguage.text_no_incidents_reported) {
        return this.statusPageLanguage.text_no_incidents_reported
      }

      return 'No incidents reported.'
    },

    textMaintenanceHistory () {
      if (this.statusPageLanguage.text_maintenance_history) {
        return this.statusPageLanguage.text_maintenance_history
      }
      return 'Maintenance History'
    }
  },
  filters: {
    date (timestamp) {
      const locale = getUserLocale()

      const day = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })

      return day.format(new Date(timestamp * 1000))
    }
  }
}
</script>

<style lang="scss" scoped>
.Incidents {
  .title {
    font-size: 27px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .date {
    margin-bottom: 40px;
  }

  .date-title {
    font-size: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    //border-bottom: 1px solid map-get($colors, gray-3);
  }

  .no-incidents {
    height: 120px;
    background: rgba(25, 34, 29, 0.03);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(25, 34, 29, .5);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: -10px 0;
  }
}
</style>
